$breakpoint-xsm: 340px;
$breakpoint-sm: 640px;
$breakpoint-md: 769px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

@mixin xs-only {
  @media (max-width: calc(#{$breakpoint-sm} - 1)) {
    @content;
  }
}

@mixin xsm-down {
  @media (max-width: $breakpoint-xsm) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

$breakpoint-short: 768px;

@mixin md-up-short {
  @media (min-width: $breakpoint-md) and (max-height: $breakpoint-short) {
    @content;
  }
}

$size-xs: 4px;
$size-sm: 8px;
$size-md: 16px;
$size-lg: 24px;
$size-xl: 32px;
$size-xxl: 64px;
$size-xxxl: 96px;

$size-header: 64px;
$size-header-tablet-up: 70px;
