@import "@/styles/layout";

.inner {
  display: block;
  width: 100%;

  @include md-up {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .description {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    @include md-up {
      flex-direction: row-reverse;
      align-items: center;
      padding: 40px;
      gap: 48px;
    }

    .image {
      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      max-width: 400px;
      flex: 1;
      border-radius: 19px;
      margin-right: auto;
      margin-bottom: 18px;
      margin-left: auto;

      @include md-up {
        overflow: hidden;
        border-radius: 19px;
        margin: 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .item {
      display: block;
      flex: 1;

      .text {
        line-height: 23px;

        ol {
          padding-inline-start: 16px;
        }
      }

      .title {
        margin: 0 0 25px;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
      }
    }
  }

  .descriptionReverse {
    @include md-up {
      flex-direction: row;
    }
  }

  .descriptionColumn {
    @include md-up {
      flex-direction: column;
      gap: 16px;

      .image {
        max-width: 600px;
      }
    }
  }
}
