@import "@/styles/layout";

.view {
  display: flex;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
  text-align: center;

  @include md-up {
    height: calc(100% - 80px);
  }
}

.header {
  .title {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
  }

  @include xsm-down {
    margin: 0 0 5px;
  }

  .logo {
    width: 190px;
    height: 26px;

    @include md-up {
      width: 256px;
      height: 37px;
    }
  }
}

.subtitle {
  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
}

.imageWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 330px;
  justify-content: center;

  @include xsm-down {
    max-width: 250px;
    margin-bottom: 5px;
  }

  @include md-up {
    max-width: 400px;
    margin: 0;
  }

  .image {
    width: 100%;
    height: auto;
    fill: transparent;
  }
}

.actions {
  position: sticky;
  bottom: 10px;
  display: flex;
  width: 100%;
  max-width: 330px;
  padding-top: 30px;

  @include md-up {
    bottom: 32px;
    max-width: 400px;
  }

  .button {
    width: 100%;
  }
}

.poster {
  max-height: 40%;

  @include md-up {
    width: 100%;
  }
}

.popup {
  position: fixed;
  inset: 0;
}
