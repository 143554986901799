@import "@/styles/layout";

.header {
  align-self: stretch;

  .title {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
  }

  @include xsm-down {
    margin: 0 0 5px;
  }

  .logo {
    width: 190px;
    height: 26px;

    @include md-up {
      width: 256px;
      height: 37px;
    }
  }
}

.subtitle {
  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
}

.imageWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 330px;
  justify-content: center;

  @include xsm-down {
    max-width: 250px;
    margin-bottom: 5px;
  }

  @include md-up {
    max-width: 400px;
    margin: 0;
  }

  .image {
    width: 100%;
    height: auto;
    fill: transparent;
  }
}

.actions {
  position: sticky;
  bottom: 10px;
  display: flex;
  width: 100%;
  max-width: 330px;
  padding-top: 30px;

  @include md-up {
    bottom: 32px;
    max-width: 400px;
  }

  .button {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9px 10px;
    border: 1px solid transparent;
    border-radius: 49px;
    background-color: var(--color-bgButtonSecondary);
    color: #1F1E1E;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;

    span {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px 26px;
      border-radius: 54px;
      background: var(--color-yellow);

      @include md-up {
        padding: 16px 26px;
      }
    }
  }
}

.poster {
  max-height: 40%;

  @include md-up {
    width: 100%;
  }
}

.popup {
  position: fixed;
  inset: 0;
}

.layout {
  z-index: 10;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  color: var(--color-textMain);
  background-color: var(--color-bgMain);
  inset: 0;
  overflow-y: scroll;

  & > * {
    flex-shrink: 0;
  }

  .content {
    display: flex;
    width: 100%;

    /* fall-back */
    min-height: 100vh;
    /* stylelint-disable-next-line -- mobile viewport bug fix */
    min-height: -webkit-fill-available;

    // disable -webkit-fill-available in Chrome
    @supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:"*") {
      min-height: 100vh;
    }

    padding: 20px 16px 32px;

    @include xsm-down {
      padding: 15px 16px 27px;
    }

    @include md-up {
      padding: 20px 40px 100px;
    }

    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }

  .additionalContent {
    display: flex;
    width: 100%;
    height: unset;
    min-height: 50vh;
    padding: 20px 16px;
    justify-content: center;

    @include xsm-down {
      padding: 15px 16px;
    }

    @include md-up {
      padding: 20px 0;
    }

    > * {
      max-width: 960px;
    }
  }

  .additionalContent:nth-child(odd) {
    background: var(--color-bgSecondary);

    .squareFrame {
      .inner {
        .description {
          flex-direction: row;
        }
      }
    }
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 10px;

    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      a {
        font-size: 14px;
        font-weight: 500;
        margin: 10px 20px;

        @include xsm-down {
          margin: 10px 5px;
        }
      }
    }
  }
}
