@import "@/styles/layout";

.list {
  display: grid;
  padding: 0;
  margin: 0;
  grid-row-gap: 10px;
  list-style: none;

  @include md-up {
    grid-row-gap: 12px;
  }

  .listItem {
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
    font-size: 15px;
    line-height: 18px;

    &::before {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11' fill='%23FFD84D'/%3E%3Cpath d='M15.2751 16.5L12.5673 13.992L13.6504 12.984L16.3453 15.492L15.2751 16.5ZM12 16.5C11.0716 16.5 10.2701 16.312 9.59527 15.936C8.92478 15.56 8.40688 15.034 8.04155 14.358C7.68052 13.682 7.5 12.896 7.5 12C7.5 11.104 7.68052 10.318 8.04155 9.642C8.40688 8.966 8.92478 8.44 9.59527 8.064C10.2701 7.688 11.0716 7.5 12 7.5C12.9284 7.5 13.7278 7.688 14.3983 8.064C15.0731 8.44 15.591 8.966 15.952 9.642C16.3173 10.318 16.5 11.104 16.5 12C16.5 12.896 16.3173 13.682 15.952 14.358C15.591 15.034 15.0731 15.56 14.3983 15.936C13.7278 16.312 12.9284 16.5 12 16.5ZM12 14.964C12.5888 14.972 13.0788 14.854 13.4699 14.61C13.861 14.366 14.1533 14.02 14.3467 13.572C14.5444 13.124 14.6433 12.6 14.6433 12C14.6433 11.4 14.5444 10.88 14.3467 10.44C14.1533 10 13.861 9.658 13.4699 9.414C13.0788 9.17 12.5888 9.044 12 9.036C11.4112 9.028 10.9212 9.146 10.5301 9.39C10.139 9.634 9.84456 9.98 9.64685 10.428C9.45344 10.876 9.35673 11.4 9.35673 12C9.35673 12.6 9.45344 13.12 9.64685 13.56C9.84456 14 10.139 14.342 10.5301 14.586C10.9212 14.83 11.4112 14.956 12 14.964Z' fill='%23242424'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
    }
  }

  .listValue {
    width: 33px;
    margin-right: 10px;
    color: var(--color-text-main);
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
}
